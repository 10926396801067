import type { IUserInfo, IUserState } from '~/types/user'

import config from '~/config'
import { request } from '~/utils/api'

export const getAccountState = (): Promise<IUserState> => {
  return request({
    url: `${config.MWM_AUTH_API_URL}/state`,
  })
}

export const getInfo = (): Promise<IUserInfo> => {
  return request({
    url: `${config.MWM_TECH_PLATFORM_URL}/tmp/get-info`,
  })
}
