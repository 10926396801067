import { useQuery, type UseQueryResult } from 'react-query'

import type { IUserInfo, IUserState } from '~/types/user'

import { getAccountState, getInfo } from '~/api/user'

////////// KEYS //////////
export const keys = {
  all: ['users'] as const,
  info: () => [...keys.all, 'info'] as const,
  state: (accessToken: string) => [...keys.all, 'state', accessToken] as const,
}

////////// QUERIES //////////
export const useGetAccountStateQuery = (accessToken: string): UseQueryResult<IUserState> =>
  useQuery(keys.state(accessToken), () => getAccountState(), { enabled: !!accessToken })

export const useGetUserInfoQuery = (): UseQueryResult<IUserInfo> => useQuery(keys.info(), getInfo)
