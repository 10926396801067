'use client'

import { useRouter } from 'next/navigation'
import { useEffect, useContext } from 'react'

import type { ReactElement } from 'react'

import ProgressCircle from '~/components/Button/ProgressCircle'
import { UserContext } from '~/contexts/UserContext'

const NotFound = (): ReactElement => {
  /** Local state */

  const { account } = useContext(UserContext)

  const router = useRouter()

  /** Effects */

  useEffect(() => {
    router.push(`${account ? '/account' : '/authentication'}`)
  }, [account, router])

  return (
    <div style={{ alignItems: 'center', display: 'flex', height: '100vh', justifyContent: 'center' }}>
      <ProgressCircle isLoading={false} strokeColor={''} trackColor={''} />
    </div>
  )
}

export default NotFound
