'use client'

import { useMemo, createContext, useEffect, useCallback } from 'react'
import { useCookies } from 'react-cookie'
import { useQueryClient } from 'react-query'

import type { PropsWithChildren, ReactElement } from 'react'
import type { IAccountState, IUserState } from '~/types/user'

import { COOKIES } from '~/const'
import { useGetAccountStateQuery, keys as userKeys } from '~/queries/user'
import { createUUID } from '~/utils/uuid'

export const UserContext = createContext<{
  accessToken?: string
  account?: IUserState
  installationId?: string
  isLoading?: boolean
  logout: () => void
  setAuthenticationCookies: (authorizedState: IAccountState) => void
}>({
  installationId: '',
  logout: () => undefined,
  setAuthenticationCookies: () => undefined,
})

const UserContextProvider = ({ children }: PropsWithChildren): ReactElement => {
  /** Local state */

  const queryClient = useQueryClient()

  const [cookies, setCookie, removeCookie] = useCookies([COOKIES.INSTALLATION_ID, COOKIES.TOKENS])

  /** Queries & Mutations */

  const { data: account, isLoading } = useGetAccountStateQuery(cookies[COOKIES.TOKENS]?.access_token)

  /** Handlers */

  const logout = useCallback(() => {
    removeCookie(COOKIES.TOKENS, { path: '/' })
  }, [removeCookie])

  const setAuthenticationCookies = useCallback(
    (authorizedState: IAccountState) => {
      const { refresh_token } = authorizedState
      setCookie(COOKIES.TOKENS, authorizedState, { path: '/' })
      setCookie(COOKIES.REFRESH_TOKEN, refresh_token, { path: '/' })
    },
    [setCookie]
  )

  useEffect(() => {
    queryClient.invalidateQueries(userKeys.state(cookies[COOKIES.TOKENS]?.access_token))
  }, [cookies, queryClient])

  useEffect(() => {
    if (!cookies[COOKIES.INSTALLATION_ID]) {
      setCookie(COOKIES.INSTALLATION_ID, createUUID(), { path: '/' })
    }
  }, [cookies, setCookie])

  const userContext = useMemo(
    () => ({
      accessToken: cookies[COOKIES.TOKENS]?.access_token,
      ...(cookies[COOKIES.TOKENS]?.access_token
        ? {
            account,
          }
        : {
            account: undefined,
          }),
      installationId: cookies[COOKIES.INSTALLATION_ID],
      isLoading,
      logout,
      setAuthenticationCookies,
    }),
    [account, cookies, logout, setAuthenticationCookies, isLoading]
  )

  return <UserContext.Provider value={userContext}>{children}</UserContext.Provider>
}

export default UserContextProvider
