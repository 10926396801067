'use client'

import classNames from 'classnames'
import { useEffect, useState } from 'react'

import type { ReactElement } from 'react'

interface Props {
  className?: string
  isLoading: boolean
  strokeColor: string
  trackColor: string
}

const ProgressCircle = ({ className, isLoading, strokeColor, trackColor }: Props): ReactElement => {
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    let interval: ReturnType<typeof setInterval> | undefined

    if (isLoading) {
      interval = setInterval(() => {
        setProgress((state) => (state + 1 > 100 ? 0 : state + 1))
      }, 50)
    }

    return () => {
      clearInterval(interval)
    }
  }, [isLoading])

  const circleSize = 24

  const strokeWidth = 3

  const radius = (circleSize - strokeWidth) / 2

  const transform = `rotate(-90 ${circleSize / 2} ${circleSize / 2})`

  const circumference = 2 * Math.PI * radius

  const dash = (progress * circumference) / 100

  const classes = classNames('progress-circle-component', className)

  return (
    <svg className={classes} height={circleSize} width={circleSize}>
      <g transform={transform}>
        <circle
          cx={circleSize / 2}
          cy={circleSize / 2}
          fill="none"
          r={radius}
          stroke={trackColor}
          strokeWidth={`${strokeWidth}px`}
        />
        <circle
          cx={circleSize / 2}
          cy={circleSize / 2}
          fill="none"
          r={radius}
          stroke={strokeColor}
          //@ts-expect-error typing error
          strokeDasharray={[dash, circumference - dash]}
          strokeLinecap="round"
          strokeWidth={`${strokeWidth}px`}
        />
      </g>
    </svg>
  )
}

export default ProgressCircle
